// const usecases = [
//   "weare.travx.agency",
//   "weare.Travx.center",
//   "weare.mytravx.com",
//   "qa.mytravx.com",
//   "weare.qa.mytravx.com",
//   "http://www.miles.onrender.com",
//   "http://www.agency.miles.onrender.com",
//   "http://miles.onrender.com",
//   "http://agency.miles.onrender.com",
//   "http://www.miles.app",
//   "http://www.agency.miles.app",
//   "http://miles.app",
//   "http://agency.miles.app",
//   "agency.miles.app",
//   "www.agency.miles.app",
//   "miles.app",
//   "agency.localhost.et",
//   "agency.localhost",
//   "localhost.et",
//   "localhost",
// ]

export const domainList = [
  'qa.mytravx.com',
  'mytravx.com',
  'travx.center',
  'qa.travx.center',
  'travx.agency',
  'qa.travx.agency',
  'intraversewebservices.com',
]

export function getsubDomain(url=window.location.href) {
    if(!window.location.host?.includes('localhost') && [...domainList,'intraverse.app']?.every(domain => !url?.includes(domain)))
      return window.location.host?.replace('www.','');


    const domains = ['.onrender','frontend.dev.','frontend.qa.','frontend.staging.','qa.']
  
    let modURL = url;
  
    try {
      modURL = modURL.replace('http://','')
      modURL = modURL.replace('https://','')
  
      domains.forEach(domain => {modURL = modURL.replaceAll(domain,'')})
  
      let hostname = (new URL('http://'+modURL)).hostname;
      let seg = hostname.split('.');
      
      if (seg.length > 2) {
        return seg?.at(-3) !== 'www' ? 
          seg?.at(-3) === 'customer' ? (seg?.at(-4) || '.')
          : seg?.at(-3)
        : null;
      } else if(seg?.at(-1)?.toLowerCase() === 'localhost' && seg.length > 1) {
        return seg[0]
      }
    } catch(ex) {}
    return null; // or any other default value you prefer
}

export function isCustomerSite(url=window.location.href) {
  if(!window.location.host?.includes('localhost') && [...domainList,'intraverse.app']?.every(domain => !url?.includes(domain)))
    return true;

  
  const domains = ['.onrender','frontend.dev.','frontend.qa.','frontend.staging.','qa.']
  
  let modURL = url;

  try {
    modURL = modURL.replace('http://','')
    modURL = modURL.replace('https://','')

    domains.forEach(domain => {modURL = modURL.replaceAll(domain,'')})

    let hostname = (new URL('http://'+modURL)).hostname;
    let seg = hostname.split('.');
    
    let segMod = seg
    if (seg.length > 2) {
      if (segMod?.at(-1) === 'localhost')
        segMod.push('com')
      return segMod?.at(-3) !== 'www' ? 
        segMod?.at(-3) === 'customer' ? true
        : false
      : null;
    } else if(segMod?.at(-1)?.toLowerCase() === 'localhost' && segMod.length > 1) {
      return false
    }
  } catch(ex) {}
  return null; // or any other default value you prefer
}