import React, { createContext, useEffect, useState } from "react";
import useOrders from "../../hooks/useOrders";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { flightStatusMap } from "../../pages/Orders/OrdersData";
import PaymentMethod from "../flight/PaymentMethod";
import Modal1 from "../DIsplay/Modal/Modal1";
import CancelTicket from "../../pages/Orders/CancelTicket";
import CancelOrder from "../../pages/Orders/cancelOrder";
import { isGDSSupplier } from "../../data/flight/supplier/getSupplierName";
import IssueTicket from "../../pages/Orders/IssueTicket";
import TicketVoid from "../../pages/Orders/VoidTicket";


const ActionContext = createContext();

function OrderList({perPage=10,reload}) {
  const { getOrders } = useOrders();
  const [orders,ogData] = getOrders() || [[],[]];

  const [openPayment,setOpenPayment] = useState(false);
  const [openCancelTicket,setOpenCancelTicket] = useState(false);
  const [openCancelOrder,setOpenCancelOrder] = useState(false);
  const [openIssueTicket, setOpenIssueTicket] = useState(false);
  const [openVoidTicket, setOpenVoidTicket] = useState(false);

  
  const {ticketsPage:page} = useSelector(state => state.order);
  const offset = ((page||1)-1)*perPage;
  return (
    <div>
      <ActionContext.Provider value={{
        reload,
        openPayment, setOpenPayment,
        openCancelTicket, setOpenCancelTicket,
        openCancelOrder, setOpenCancelOrder,
        openIssueTicket, setOpenIssueTicket,
        openVoidTicket, setOpenVoidTicket,
      }}>
        <div className="w-full flex flex-col mt-4 bg-[#F0F6FC] gap-3 p-1">
          {orders?.slice(offset,offset+perPage).map((order, index) => (
            <Order key={index} order={order} og={ogData?.slice(offset,offset+perPage)?.at(index)} />
          ))}
        </div>
      </ActionContext.Provider>

      <ActionHandles {...{reload}}
        {...{openPayment,setOpenPayment}} 
        {...{openCancelOrder,setOpenCancelOrder}}
        {...{openCancelTicket,setOpenCancelTicket}}
        {...{openIssueTicket,setOpenIssueTicket}}
        {...{openVoidTicket,setOpenVoidTicket}}
       />

    </div>
  );
}

function ActionHandles({
  reload,
  openPayment, setOpenPayment,
  openCancelTicket, setOpenCancelTicket,
  openCancelOrder, setOpenCancelOrder,
  openIssueTicket,setOpenIssueTicket,
  openVoidTicket,setOpenVoidTicket,
}) {
  return (
    <div>
      <Modal1 open={openPayment} setOpen={setOpenPayment}>
        <PaymentMethod
          callback={() => {
            reload && reload();
            setOpenPayment(false);
          }}
          flightBookingId={openPayment}
          hide={["booklater"]}
          expand
        />
      </Modal1>

      <CancelOrder
        open={openCancelOrder}
        setOpen={setOpenCancelOrder}
        callback={() => {
          reload && reload();
          setOpenCancelOrder(false);
        }}
      />


      <Modal1 open={openCancelTicket} setOpen={setOpenCancelTicket}>
        <CancelTicket
          data={openCancelTicket}
          callback={() => {
            reload && reload();
            setOpenCancelTicket(false);
          }}
          close={() => setOpenCancelTicket(false)}
        />
      </Modal1>

      <Modal1 open={openIssueTicket} setOpen={setOpenIssueTicket}>
        <IssueTicket
          data={openIssueTicket}
          callback={() => {
            reload && reload();
            setOpenIssueTicket(false);
          }}
          close={() => setOpenIssueTicket(false)}
        />
      </Modal1>

      <Modal1 open={openVoidTicket} setOpen={setOpenVoidTicket}>
        <TicketVoid
          data={openVoidTicket}
          callback={() => {
            reload && reload();
            setOpenVoidTicket(false);
          }}
          close={() => setOpenVoidTicket(false)}
        />
      </Modal1>
      
    </div>
  )
}

export default OrderList;

const Order = ({ order, og }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="w-full flex  justify-between bg-white p-4 gap-3">
      <div className="flex flex-col md:flex-row justify-between flex-1">
        {order?.map(({ elements, width }, index) => (
          <div
            className={`w-full ${width || "flex-1"} ${
              !open && index !== 0 ? "hidden md:flex" : "flex"
            } flex-col ${open && "border-b pb-3"} mb-2 px-2 md:border-b-0 md:pb-0`}
            key={index}
          >
            {elements?.map((element, idx) => (
              <OrderContent element={element} key={idx} />
            ))}
          </div>
        ))}
      </div>
      <div className="flex flex-col justify-between items-center ">
        <Menu og={og} order={order} />
        <div className="flex md:hidden" onClick={() => setOpen(!open)}>
          {open ? (
            <img
              src="/IconChevronUp.svg"
              alt=""
              className="w-5 object-contain"
            />
          ) : (
            <img
              src="/IconChevronDown.svg"
              alt=""
              className="w-5 object-contain"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const OrderContent = ({ element }) => {
  if(element?.render)
    return element?.render();
  
  switch (element.type) {
    case "title":
      return <Title value={element.value} />;
    case "copy":
      return <Copy value={element.value} render={element?.renderValue} />;
    case "tag":
      return <Tag element={element} />;
    case "time":
      return <Time value={element?.value} />;
    case "status":
      return <Status value={element?.value} />;
    default:
      return <Text value={element.value} />;
  }
};

const Text = ({ value }) => {
  return <span className="text-sm mb-2">{value}</span>;
};

const Title = ({ value }) => {
  return <span className="text-sm text-[#6D7C94] mb-2">{value}</span>;
};

const Copy = ({ value, render }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(value);
      enqueueSnackbar("Copied to clipboard!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to copy to clipboard!", { variant: "error" });
    }
  };
  return (
    <div
      className="flex gap-1 items-center mb-2 cursor-pointer"
      onClick={handleCopy}
    >
      <span className="text-xs text-primary1">{render ? render() : value}</span>
      <img src="/IconCopy.svg" alt="" />
    </div>
  );
};

const Tag = ({ element }) => {
  return (
    <div className={`rounded-md `}>
      <span
        className={`${element?.color} ${element?.background} text-xs font-bold px-2 py-1 rounded-md ${element?.className}`}
      >
        {element?.value}
      </span>
    </div>
  );
};

const Time = ({ value }) => {
  return (
    <div className="flex gap-1 items-center mb-2 cursor-pointer">
      <img src="/IconClock1.svg" alt="" />
      <span className="text-xs">{value}</span>
    </div>
  );
};

const Status = ({ value }) => {
  return (
    <div className={`rounded-md mb-3`}>
      <span
        className={`bg-[#FDF3D7] text-[#6B5618] text-xs font-bold px-2 py-1 rounded-md `}
      >
        {value}
      </span>
    </div>
  );
};

const Menu = ({ order, og }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {ticketType} = useSelector(state => state.order);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (open) {
        setTimeout(() => {
          setOpen(false);
        }, 400);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const handleSelect = () => {
    navigate("/order/flight/ticket/detail");
  };

  const menu = () => {
    let menus = ["Cancel order", "Open order", "Refund"]
    return menus
  };

  const MenuType = () => {
    switch(ticketType) {
      case 'Flights':
        return <FlightMenu og={og} />
      default:
        <div className="absolute py-2 flex flex-col bg-white rounded-md right-6 top-4 shadow-md min-w-[200px]">
          {menu()?.map((item,index) => (
            <div key={index}>
              {item}
            </div>
          ))}
        </div>
    }
  }

  return (
    <div className="cursor-pointer p-2 relative " onClick={() => setOpen(true)}>
      <img src="/IconMore.svg" alt="" className="h-5 object-contain relative" />
      <div className="">
        {open && 
          <MenuType />
        }
      </div>
    </div>
  );
};

function FlightMenu({og}) {
  const [openPayment,setOpenPayment] = useState(false);
  const bookingData = og?.flightBooking.at(0);

  const navigate = useNavigate();
  
  return (
    <div className="absolute p-2 flex flex-col z-10 bg-white rounded-md right-2 top-full shadow-xl min-w-[200px]"
     style={{
      boxShadow: '0 5px 15px #999'
     }}
    >
      <ActionContext.Consumer>
        {(value) => {
          const {
            reload,
            setOpenPayment,
            setOpenCancelOrder,
            setOpenIssueTicket,
            setOpenVoidTicket,
          } = value || {}
          // Ticketed:
          // x View order
          // x Add a bag
          // x Change date
          // x Change name
          // Void
          // x Refund (if refundable)

          // Pending Payment:
          // View order
          // Make payment
          // Hold booking
          // Add hotel
          // Add tour
          // Add insurance 
          // Add bag
          // Add a seat
          // Cancel order

          // PAID:
          // View order
          // Issue ticket
          // Add hotel
          // Add tour
          // Add insurance 
          // Add bag
          // Add a seat
          // Cancel order
        
          // Cancelled:
          // View order
          // Rebook order
          
          // Share
          // Share pdf
          // Share via email

          const menus = [
            {
              label: "View order",
              action: () => navigate("/order/flight/ticket/detail/"+og?._id),
            },
            {
              label: 'Make payment',
              action: () => setOpenPayment(og?.flightObj?._id),
              showFor: ['Not Paid','booked']
            },
            {
              label: 'Issue Ticket',
              action: () => setOpenIssueTicket(og?.flightObj),
              showFor: ['Paid','issuable']
            },
            {
              label: 'Hold booking',
              action: () => {},
              showFor: ['Not Paid','booked']
            },
            {
              label: 'Add a bag',
              action: () => {},
              showFor: ['Not Paid','booked','Ticket issued','issued','Paid','issuable']
            },
            {
              label: 'Add a seat',
              action: () => {},
              showFor: ['Not Paid','booked','Ticket issued','issued','Paid','issuable']
            },
            {
              label: 'Add hotel',
              action: () => {},
              showFor: ['Not Paid','booked','Paid','issuable']
            },
            {
              label: 'Add tour',
              action: () => {},
              showFor: ['Not Paid','booked','Paid','issuable']
            },
            {
              label: 'Add insurance',
              action: () => {},
              showFor: ['Not Paid','booked','Paid','issuable']
            },
            {
              label: 'Rebook',
              action: () => {},
              showFor: ['Expired','Canceled','TIcket Canceled']
            },
            {
              label: 'Change date',
              action: () => {},
              showFor: ['Ticket issued','issued']
            },
            {
              label: 'Change name',
              action: () => {},
              showFor: ['Ticket issued','issued']
            },
            {
              label: 'Refund',
              action: () => navigate('/order/flight/ticket/refund/'+og?._id),
              showFor: ["Ticket issued", "issued"],
              // hide: bookingData?.isRefundable
            },
            {
              label: 'Download pdf',
              action: () => {},
            },
            {
              label: 'Share order',
              action: () => {},
            },
            {
              label: 'Share pnr',
              action: () => {},
              hide: !isGDSSupplier(bookingData?.supplier)
            },
            {
              label: 'Issue via intraverse',
              action: () => {},
              hide: !isGDSSupplier(bookingData?.supplier)
            },
            {
              label: 'Cancel order',
              action: () => setOpenCancelOrder(og?.flightObj?._id),
              showFor: ["Not Paid", "booked","Paid",'issuable']
            },
            {
              label: 'Void',
              action: () => navigate('/order/flight/ticket/void/'+og?._id),
              showFor: ["Ticket issued", "issued"],
            },
          ]
      
          let items = menus?.filter(obj => {
            const bookingData = og?.flightBooking?.at(0)
            const status = flightStatusMap(bookingData?.status);
            console.log(status,obj?.showFor)
            return (obj?.showFor ? obj?.showFor?.includes(status) : true) && !obj?.hide
          })
          
          return (
            (items||[])?.map((item, index) => (
              <div
                key={index}
                onClick={() => item?.action()}
                className="font-bold text-sm text-[#444] px-2 py-2 hover:bg-[#818083]/10 border-b"
              >
                {item?.label}
              </div>
            ))
          )
        }}
      </ActionContext.Consumer>

    </div>
  )
}